import React from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import { graphql, PageProps } from "gatsby";
import { ContentAbout } from "../components/content_about";
import { ContentImageAndInformation } from "../components/content_image_and_information";
type Props = {
  data: GatsbyTypes.AboutListQuery;
};

export const IndexPage: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Header />
      <Layout>
        <ContentImageAndInformation data={data} />
        <ContentAbout data={data} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query AboutList {
    allMicrocmsAbout(sort: { order: ASC, fields: [sortIndex, createdAt] }) {
      totalCount
      edges {
        node {
          createdAt
          updatedAt
          publishedAt
          aboutId
          title
          body
          hasLink
          linkTo
          linkLabel
          sortIndex
        }
      }
    }
    allMicrocmsPost(
      filter: { showInformation: { eq: true } }
      sort: { order: [DESC, ASC], fields: [publishedAt, createdAt] }
    ) {
      totalCount
      edges {
        node {
          id
          createdAt
          publishedAt
          updatedAt
          postId
          sortIndex
          title
          lead
        }
      }
    }
  }
`;

export default IndexPage;
