import React from "react";
import { EuiCard, EuiFlexItem, EuiTextColor, EuiLink } from "@elastic/eui";
import { css } from "@emotion/react";
import * as Days from "../../libs/days";

type Props = {
  information: GatsbyTypes.AboutListQuery["allMicrocmsPost"];
};

export const ContentInformationCards: React.FC<Props> = ({ information }) => {
  const nodes = information.edges.map(({ node }) => {
    const date = Days.getDateJisX301(Days.getDate(node.publishedAt ?? ""));
    return (
      <>
        <small>
          <EuiTextColor color="subdued">{date}</EuiTextColor>
        </small>{" "}
        <EuiLink href={`/information/${node.postId}/`}>
          {node.title}
        </EuiLink>
        <br />
      </>
    );
  });
  return (
    <EuiFlexItem
      css={css`
        min-width: 280pt;
        font-family: "Yuji Syuku", serif;
      `}
    >
      <EuiCard
        // icon={<EuiIcon size="xxl" type={`logo${item}`} />}
        css={css`
          white-space: pre-line;
        `}
        title="お知らせ"
        // isDisabled={item === 'Kibana' ? true : false}
        description={nodes}
        textAlign="left"
        // onClick={() => {}}
        // hasBorder={false}
      />
    </EuiFlexItem>
  );
};

export default ContentInformationCards;
