import React from "react";
import { EuiImage, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import { css } from "@emotion/react";
import { ContentInformationCards } from "./content_information_cards";

const imageUrl = [
  "https://images.microcms-assets.io",
  "/assets/7b53e4106fce48b3bf5afe5912f99c3c/45bb1616875349aab319c6fc241106af/ていね夏あかり.png",
  "?" +
    [
      "h=300",
      "txt-size=24",
      "txt-align=bottom,right",
      "txtfont=Hiragino%20Sans%20W6",
      "txt-line=1",
      "txt-line-color=fff",
      "txt=2019/07/15%20ていね夏あかり",
    ].join("&"),
].join("");

type Props = {
  data: GatsbyTypes.AboutListQuery;
};

export const ContentImageAndInformation: React.FC<Props> = ({ data }) => {
  return (
    <>
      <>
        <EuiFlexGroup justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            <EuiImage
              size="xl"
              // hasShadow
              // caption="Random nature image"
              alt="Teamwork is important."
              src={imageUrl}
              hasShadow={true}
              css={css`
                img {
                  border-radius: 5px;
                }
              `}
            />
          </EuiFlexItem>
          {0 < data.allMicrocmsPost.edges.length && (
            <ContentInformationCards
              information={data.allMicrocmsPost}
            />
          )}
        </EuiFlexGroup>
      </>
    </>
  );
};

export default ContentImageAndInformation;
