import React from "react";
import { EuiCard, EuiFlexItem, EuiButton } from "@elastic/eui";
import { css } from "@emotion/react";

type Props = {
  about: GatsbyTypes.AboutListQuery["allMicrocmsAbout"];
};

export const ContentAboutCards: React.FC<Props> = ({ about }) => {
  const cardNodes = about.edges.map(({ node }) => (
    <>
      <EuiFlexItem
        css={css`
          min-width: 240pt;
          font-family: "Yuji Syuku", serif;
          .euiButton {
            font-family: "Yuji Syuku", serif;
          }
        `}
        key={node.aboutId}
      >
        <EuiCard
          // icon={<EuiIcon size="xxl" type={`logo${item}`} />}
          css={css`
            white-space: pre-line;
          `}
          title={node.title ?? ""}
          // isDisabled={item === 'Kibana' ? true : false}
          description={node.body ?? ""}
          footer={
            node.hasLink && (
              <>
                <EuiButton
                  href={node.linkTo ?? ""}
                  target="_blank"
                  iconType="popout"
                >
                  {node.linkLabel ?? ""}
                </EuiButton>
              </>
            )
          }
          // onClick={() => {}}
          // hasBorder={false}
        />
      </EuiFlexItem>
    </>
  ));
  return <>{cardNodes}</>;
};

export default ContentAboutCards;
