import React from "react";
import { EuiFlexGroup, EuiSpacer } from "@elastic/eui";
// import { css } from "@emotion/react";
import { ContentAboutCards } from "./content_about_cards";

type Props = {
  data: GatsbyTypes.AboutListQuery;
};

export const ContentAbout: React.FC<Props> = ({ data }) => {
  return (
    <>
      <EuiFlexGroup gutterSize="l" wrap>
        <ContentAboutCards about={data.allMicrocmsAbout} />
      </EuiFlexGroup>
      <EuiSpacer size="m" />
    </>
  );
};

export default ContentAbout;
